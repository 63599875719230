import React, { useState } from 'react';
import PropTypes from 'prop-types';
import './ConfirmNumber.scss';
import { Title } from '../Title'
import { Input } from '../Input';
import { Button } from '../Button';
import {
  requestNewVerificationCode,
  checkVerificationCode,
} from '../../queries/verification';

const ConfirmNumber = ({
  phoneNumber,
  setConfirm
}) => {
  const [ userCode, setUserCode ] = useState();
  const shieldedNumber = '********' + phoneNumber.slice(-3);
  console.log(phoneNumber);


  const onInputChange = (e) => {
    setUserCode(e.target.value);
    console.log(e.target.value);
    if (e.target.value.length === 6) {
      checkVerificationCode(phoneNumber, e.target.value, setConfirm);
    }
  }
  
  return (
    <div className='confirm-number'>
      <div className='confirm-number__container'>
        <Title level={3}>Great! The next step is to confirm this mobile number.</Title>
        <Input
          placholder='Enter your code'
          value={userCode}
          onChange={(e) => onInputChange(e)}
        />
        <div>{`Enter the code sent to ${shieldedNumber}`}</div>
        <Button onClick={() => requestNewVerificationCode(phoneNumber, userCode)}>Generate a new code</Button>
        <div>Code will expire after 3 minutes.</div>
      </div>
    </div>
  )
};

ConfirmNumber.propTypes = {
  phoneNumber: PropTypes.string,
  setConfirm: PropTypes.func,
};

ConfirmNumber.defaultProps = {
  placeholder: '',
  setConfirm: () => {},
};

export default ConfirmNumber;