import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import './_style.scss';

// Add placeholder logic
// e.g.
// pass width and height and placeholder flag to auto generate a placeholder
// using this url format:
// https://via.placeholder.com/468x60?text=Visit+Blogging.com+Now
// https://via.placeholder.com/150/FF0000/FFFFFF?Text=Down.com

// width x height / background colour / text colour / text

const Image = ({
  src,
  alt,
  width,
  height,
  placeholder,
  extraClassnames,
}) => {
  if (placeholder) {
    src = `https://via.placeholder.com/${width}x${height}`;
  }
  
  return (
    <img
      src={src}
      alt={alt}
      width={width}
      height={height}
      className={classNames(`image`, extraClassnames)}
    />
  );
};

Image.propTypes = {
  src: PropTypes.string,
  alt: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
  placeholder: PropTypes.bool,
};

Image.defaultProps = {
  alt: "",
  placeholder: false,
}

export default Image;