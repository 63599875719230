import React from 'react';
import './Privacy.scss';
import { Paragraph } from '../../components/Paragraph';
import { Title } from '../../components/Title';

const Privacy = () => (
  <>
    <Title>
      {'A Title Here'}
    </Title>
    <Paragraph>
      {`Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna
        aliqua. Mi sit amet mauris commodo quis imperdiet massa tincidunt nunc. Aenean et tortor at risus viverra adipiscing
        at in. Nullam eget felis eget nunc lobortis mattis aliquam faucibus purus. Morbi quis commodo odio aenean. Tempus quam
        pellentesque nec nam aliquam sem. Turpis massa tincidunt dui ut ornare lectus sit amet. Duis ut diam quam nulla porttitor
        massa id neque aliquam. Magna sit amet purus gravida. Posuere urna nec tincidunt praesent semper feugiat nibh sed. Eget
        nunc scelerisque viverra mauris in aliquam sem. Enim facilisis gravida neque convallis a cras semper auctor neque. At
        quis risus sed vulputate odio. Lorem ipsum dolor sit amet consectetur adipiscing. Odio tempor orci dapibus ultrices in
        iaculis nunc. Condimentum lacinia quis vel eros donec ac odio tempor. Tempor nec feugiat nisl pretium. Turpis egestas
        maecenas pharetra convallis. Integer enim neque volutpat ac.`}
    </Paragraph>
  </>
);

export default Privacy;
