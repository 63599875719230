import React from 'react';
import PropTypes from 'prop-types';
import './_style.scss';

const Link = ({
  children,
  address,
}) => {
  return (
    <a
      href={address}
    >{children}</a>
  );
};

Link.propTypes = {
  children: PropTypes.node,
  address: PropTypes.string,
};

Link.defaultProps = {
};

export default Link;