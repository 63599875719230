import React from 'react';
import './App.scss';
import { Home } from './Pages/Home/';
import { About } from './Pages/About';
import { Privacy } from './Pages/Privacy';
import { Login } from './Pages/Login';
import { Register } from './Pages/Register';
import { RegisterSuccess } from './Pages/RegisterSuccess';
import { HowItWorks } from './Pages/HowItWorks';

import {
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";

import { Navigation } from './components/Navigation';
import { Footer } from './components/Footer';

export default function App() {
  return (
    <div className='page'>
      <Navigation />
      <div className='content'>
        <Router>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="about" element={<About />} />
            <Route path="privacy" element={<Privacy />} />
            <Route path="login" element={<Login />} />
            <Route path="register" element={<Register />} />
            <Route path="register/success" element={<RegisterSuccess />} />
            <Route path="howitworks" element={<HowItWorks />} />
          </Routes>
        </Router>
      </div>
      <Footer />
    </div>
  )
}
