import React from 'react';
import './_style.scss';

import { Link } from '../Link';
import { Image } from '../Image';

const nav_items = [
  {
    name: 'About',
    link: '/about',
    visible: true,
  },
  {
    name: 'Register',
    link: '/register',
    visible: true,
  }
];

const Navigation = () => {
  return (
    <div className='nav'>
      <Link clas address='/'>
        <Image
          src='/images/color-black-logo.svg'
          alt='Rendezvous logo'
          height={40}
          extraClassnames='logo'
        />
      </Link>
      <div className='nav-items'>
        {nav_items.map(({name, link, visible}, index) => {
          console.log(name);
          console.log(link);
          console.log(visible);
          return (
          visible && (<Link
            className='nav-item'
            children={name}
            address={link}
            key={`nav-item-${index}`}
          />)
        )})}
      </div>
    </div>
  );
};

export default Navigation;