import React from 'react';
import './About.scss';
import { Image } from '../../components/Image';
import { Bar } from '../../components/Bar';
import { Paragraph } from '../../components/Paragraph';
import { Title } from '../../components/Title';
import { data } from './data';

const About = () => (
  <div className='about'>
    <div className='about_header'>
      <Image
        alt='Mountains - Lake district'
        src='/images/mountains/LakeDistrict.jpg'
        height={300}
      />
      <Title>{data.title}</Title>
    </div>
    <Bar>
      <Paragraph>
        {data.overallDesc}
      </Paragraph>
    </Bar>
    <div className='about_content'>
      {data.content.map(({image, desc}) => (
        <div className='about_section'>
          <Image 
            alt={image.alt}
            src={image.src}
            height={image.height}
            width={image.width}
          />
          <Paragraph>{desc}</Paragraph>
        </div>
      ))}
    </div>
  </div>
);

export default About;