import React from 'react';
import './_style.scss';

const Footer = () => {
  return (
    <div className='footer'>
      <div>Lucy Blatherwick | 2023 | Rendezvous</div>
    </div>
  );
};

export default Footer;