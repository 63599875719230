export const data = {
  'title': <>Made by a <b>hiker</b> for <b>hikers</b></>,
  'overallDesc': "Rendezvous is a tool created to solve a problem. When there’s no one local to " +
    "tell that you and your friends are heading out to the hills for the day, what happens to the " +
    "recommendation that you should leave a note of your route and the time you’ll be back with someone " +
    "trustworthy? Rendezvous is designed to remove this problem, making sure you always have an option " +
    "to leave a note behind. While Rendezvous can’t currently store your route for the day, it’s " +
    "fairly easy to leave a route with a remote emergency contact ahead of your adventure - in comparison " +
    "with the faff of trying to estimate your timing a week before getting to the hill.",
  'content': [
    {
      'image': {
        'alt': 'Scouts Of the World Award Badge',
        'src': '/images/scouts-of-the-world-award.jpg',
      },
      'desc': "Rendezvous was built by Lucy as a project in early 2023 to fulfill " +
        "all the requirements of the Scouts Of the World Award (SOWA). The SOWA is an international " +
        "award that can be undertaken by any Scout Network member in the UK as well as a number " +
        "of other countries. The award requires a minimum of 80 hours to be spent completing a " +
        "voluntary project that has a long-term, sustainable impact on the local, national, " +
        "or international communities, and is based on one or more of the key themes of the " +
        "Award (Peace, Environment, Sustainable Development). Lucy chose to spend this time " +
        "working towards creating Rendezvous to enable hikers and other people who love to get " +
        "outdoors to keep exploring safely.",
    },
    {
      'image': {
        'alt': 'Lucy',
        'src': '/images/Lucy.jpg',
      },
      'desc': "Lucy chose to create Rendezvous to solve a problem that she had encountered while " +
        "out hiking in several different situations. In one particular case Lucy was with a group " +
        "that forgot to update their home contact on return from the hill - this all ended well when " +
        "their home contact actively tried to make sure they were safe and well, but with a less attentive " +
        "home contact it could have been over a day before someone would have known if an accident " +
        "had occurred or they were safe. ",
    }
  ]
};
