import React from 'react';
import './HowItWorks.scss';
import { data } from './data';
import { Title } from '../../components/Title'
import { Paragraph } from '../../components/Paragraph'

const HowItWorks = () => {
  return (
    <div className='howitworks'>
      <Title>How Rendezvous Works</Title>
      {data.map(({title, content}) => {
        return (
          <>
            <Title level={2}>{title}</Title>
            <Paragraph>{content}</Paragraph>
          </>
        )
      })}
    </div>
  );
};

export default HowItWorks;
