import React from 'react';
import PropTypes from 'prop-types';
import './_style.scss';

const Bar = ({
  barHeight,
  children
}) => {
  return (
    <div className='bar' style={{minHeight: `${barHeight}px`}}>
      <div className='bar__content-wrapper'>
        {children}
      </div>
    </div>
  );
};

Bar.propTypes = {
  children: PropTypes.node,
  barHeight: PropTypes.number,
};

Bar.defaultProps = {
  barContent: '',
  barHeight: 50,
};

export default Bar;