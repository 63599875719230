import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import './_style.scss';
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'

// Should consider replacing HTML input with react-input to avoid unnecessary work

const Input = ({
  placeholder,
  extraClassnames,
  type,
  value,
  onChange,
}) => {
  return (
    <div className={classNames(`input`, extraClassnames)}>
      {type && (type === 'phone') && (
        <PhoneInput 
          placeholder={placeholder}
          value={value}
          onChange={onChange}
        />
      )}
      {(!type || (type !== 'phone')) && (
        <input 
          placeholder={placeholder}
          value={value}
          onChange={onChange}
        />
      )}
    </div>
  );
};

Input.propTypes = {
  placeholder: PropTypes.string,
  extraClassnames: PropTypes.string,
  type: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
};

Input.defaultProps = {
  placeholder: '',
  extraClassnames: '',
  type: '',
  value: '',
  onChange: () => {},
};

export default Input;