import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import './_style.scss';

const Button = ({
  children,
  extraClassnames,
  onClick,
  isDisabled,
}) => {
  return (
    <button
      className={classNames(`button`, extraClassnames)}
      onClick={onClick}
      disabled={isDisabled}
    >
      {children}
    </button>
  );
};

Button.propTypes = {
  children: PropTypes.node,
  extraClassnames: PropTypes.string,
  onClick: PropTypes.func,
  isDisabled: PropTypes.bool,
};

Button.defaultProps = {
  children: '',
  extraClassnames: '',
  onClick: () => {},
  isDisabled: false,
};

export default Button;
