export const data = {
  title: <>Welcome to <b>Rendezvous</b></>,
  subtitle: 'Currently unknown but a nice sweet catch line to enter.',
  intro: "Rendezvous is a tool to help reduce the risks associated with heading out on adventures, " + 
    "whether that be on the hill, on the water, or anywhere else. Tell us when you’re planning to be back " + 
    "and we’ll check in with you to make sure you made it off the hill. Think of us as your home contact " +
    "who will always get in touch to make sure you’re safe. If we don’t hear from you by the the time " +
    "you’ve specified, we’ll reach out to the contacts you’ve given us.",
  steps: [
    {
      title: 'Register',
      content: "Give us your details and a emergency contact's details by signing up online.",
    },
    {
      title: 'Head out to the start of your adventure',
      content: "We'll store your details for 30 days, so you can sign up before you leave for your trip.",
    },
    {
      title: 'Start your session',
      content: "Before leaving civilization (and more importantly a phone signal) text 'start' to [rendezvous number] and answer a few questions about your timings for the day.",
    },
    {
      title: 'Head out for a great day!',
      content: '',
    },
    {
      title: 'Rendezvous',
      content: "We'll rendezvous with you at the agreed time to check in and make sure you're safe.",
    }
  ]
};
