import React from 'react';
import './Home.scss';
import { data } from './data';
import { Image } from '../../components/Image';
import { Bar } from '../../components/Bar';
import { Paragraph } from '../../components/Paragraph';
import { Title } from '../../components/Title';
import { Button } from '../../components/Button';
import { Link } from '../../components/Link';

const Home = () => {
  return (
    <div className='home'>
      <div className='home-header'>
        <Image
          alt='Mountains - Cairngorms'
          src='/images/mountains/Cairngorms1-2.jpg'
          height={400}
          extraClassnames='home_header-background'
        />
        {/*<Title extraClassNames="bob">{data.title}</Title>
        <Title level={2}>{data.subtitle}</Title>*/}
        <Image 
          alt='Rendezvous Logo'
          src='/images/color-black-logo.svg'
          height={100}
          extraClassnames='home_header-logo'
        />
      </div>
      <Bar>
        <Paragraph>
          {data.intro}
        </Paragraph>
      </Bar>
      <div className='home_step-list'>
        <ol>
          {data.steps.map(({title, content}, index) => (
            <li className='step' key={index}>
              <Title level={3}>{title}</Title>
              <div>{content}</div>
            </li>
          ))}
        </ol>
      </div>
      <Bar>
        <Link
          address='/register'
        >
          <Button extraClassnames='register-cta'>Register</Button>
        </Link>
      </Bar>
      <Paragraph>
        <Link
          address='/howitworks'
          children='Learn more here'
        />
        </Paragraph>
    </div>
  );
};

export default Home;