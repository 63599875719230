import axios from 'axios';

// Save one new user to the db
export const saveUserData = async (phoneNumber, firstName, surname, returnId) => {
  const res = await axios.post(`/api/user/saveNewUser`, null, {
    params: {
      phone: phoneNumber,
      firstname: firstName,
      surname: surname,
    }
  });

  returnId(res.data.data.id);
};

export const saveContact = async (userId, firstName, surname, phoneNumber, relation) => {

  const res = await axios.post(`/api/user/saveContact`, null, {
    params: {
      id: userId,
      phone: phoneNumber,
      firstname: firstName,
      surname: surname,
      relation: relation,
    }
  })

  console.log(res);
}