import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import './_style.scss';

const Title = ({
  children,
  level
}) => {
  return (
    <div
      className={classNames('title', `title-h${level}`)}
    >
      <div className='title__wrapper'>
        {children}
      </div>
    </div>
  );
};

Title.propTypes = {
  level: PropTypes.number,
};

Title.defaultProps = {
  level: 1,
};

export default Title;
