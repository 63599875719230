import React, { useState, useEffect } from 'react';
import './Register.scss';
import { Input } from '../../components/Input';
import { Button } from '../../components/Button';
import { ConfirmNumber } from '../../components/ConfirmNumber';
import { Contacts } from '../../components/Contacts';
import { requestNewVerificationCode } from '../../queries/verification';
import { saveUserData } from '../../queries/user';

const Register = () => {

  const [ page, setPage ] = useState('enter');

  const [ phone, setPhone ] = useState('');
  const [ firstName, setFirstName ] = useState('');
  const [ surname, setSurname ] = useState('');

  const [ confirmPhone, setConfirmPhone ] = useState(false);
  const [ userId, setUserId ] = useState('');

  useEffect(() => {
    if (confirmPhone) {
      // Send all data
      saveUserData(phone, firstName, surname, setUserId);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [confirmPhone]);

  // Click Register
  const register = () => {
    setPage('confirm')
    console.log('Youve registered');
    // Confirm number
    requestNewVerificationCode(phone);
  }

  return (
    <>
      {page && page === 'enter' && (
        <div className='register'>
          <div className='register__container'>
            <p>Welcome to Rendezvous!</p>
            <Input
              placeholder="Enter phone number"
              extraClassnames='phone'
              type='phone'
              value={phone}
              onChange={setPhone}
            />
            <Input
              placeholder='first name'
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
            />
            <Input
              extraClassnames='surname'
              placeholder='surname'
              value={surname}
              onChange={(e) => setSurname(e.target.value)}
            />
            <Button
              extraClassnames='register__submit'
              onClick={register}
              isDisabled={!(phone !== '' && firstName !== '' && surname !== '')}
            >
              Register
            </Button>
          </div>
        </div>
      )}
      {page && page === 'confirm' && !confirmPhone && (
        <ConfirmNumber phoneNumber={phone} setConfirm={setConfirmPhone}/>
      )}
      {page && page === 'confirm' && confirmPhone && (
        <Contacts userId={userId}/>
      )}
    </>
  );
};

export default Register;