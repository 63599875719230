import React from 'react';
import './RegisterSuccess.scss';
import { data } from './data';
import { Title } from '../../components/Title';
import { Paragraph } from '../../components/Paragraph';
import { Button } from '../../components/Button';

const RegisterSuccess = () => {
  return (
    <div className='registerSuccess'>
      <Title>{data.signedUpTitle}</Title>
      <Paragraph>{data.signedUpContent}</Paragraph>
      <Title level={2}>{data.whatsNextTitle}</Title>
      <Paragraph>{data.whatsNextContent}</Paragraph>
      <Button>How It Works</Button>
    </div>
  );
};

export default RegisterSuccess;
