
import axios from 'axios';

// Request check for number - pass number to backend and send code text
export const requestNewVerificationCode = async (phoneNumber) => {
  await axios(`/api/verification/send`, {
    params: {
      phone: phoneNumber
    }
  });
};

// Check that given code matches the code generated for this phone number
export const checkVerificationCode = async (phoneNumber, userCode, callback) => {
  const res = await axios(`/api/verification/check`, {
    params: {
      phone: phoneNumber,
      code: userCode
    }
  });

  callback(res.data.data.codeMatches);
}