import React from 'react';
import './_style.scss';

const Paragraph = (props) => (
  <div className='paragraph'>
    <div className='paragraph__wrapper'>
      {props.children}
    </div>
  </div>
);

export default Paragraph;