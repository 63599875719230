import React, { useState } from 'react';
import PropTypes from 'prop-types';
import './Contacts.scss';
import { Input } from '../Input';
import { Button } from '../Button';
import { saveContact } from '../../queries/user';
import { useNavigate } from "react-router-dom";

const Contacts = ({
  userId
}) => {
  const navigate = useNavigate();

  const [ firstName, setFirstName ] = useState('');
  const [ surname, setSurname ] = useState('');
  const [ relation, setRelation] = useState('');
  const [ phone, setPhone ] = useState('');

  const submit = () => {
    console.log({
      userId,
      firstName,
      surname,
      relation,
      phone
  })
    saveContact(
      userId,
      firstName,
      surname,
      phone,
      relation,
    );

    navigate('/register/success'); // Check that this correctly redirects
  };
  
  return (
    <div className='contacts'>
      <div className='contacts__container'>
        <p>Your Emergency Contact</p>
        <Input
          placeholder='first name'
          value={firstName}
          onChange={(e) => setFirstName(e.target.value)}
        />
        <Input
          placeholder='surname / family name'
          value={surname}
          onChange={(e) => setSurname(e.target.value)}
        />
        <Input
          placeholder='relationship'
          value={relation}
          onChange={(e) => setRelation(e.target.value)}
        /> {/* dropdown */}
        <Input
          placeholder='phone number'
          type='phone'
          value={phone}
          onChange={setPhone}
        />
        <Button
          extraClassnames='contacts__submit'
          onClick={submit}
          isDisabled={!(phone !== '' && firstName !== '' && surname !== '' && relation !== '')}
        >Continue</Button>
      </div>
    </div>
  );
};

Contacts.propTypes = {
  id: PropTypes.string,
};

Contacts.defaultProps = {
  id: '',
};

export default Contacts;
