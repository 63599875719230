import React from 'react';
import './Login.scss';
import { Input } from '../../components/Input';
import { Button } from '../../components/Button';

const Login = () => (
  <div className='login'>
    <div className='login__container'>
      <p>Login</p>
      <Input placeholder='email'/>
      <Input placeholder='password'/>
      <p>click here to register / forgotten password</p>
      <Button>Login</Button>
    </div>
  </div>
);

export default Login;
